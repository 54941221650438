import { Box, Button, Typography } from '@mui/material';

interface ILabellerPageNavigatorProps {
    value: number; // 1-indexed
    onChange: (value: number) => void;
    maxPage: number // 1-indexed
}


const LabellerPageNavigator: React.FC<ILabellerPageNavigatorProps> = ({
    value,
    onChange,
    maxPage,
}) => {

    const changePage = (offset: number) => {
        const newValue = value + offset;
        if (newValue >= 1 && newValue <= maxPage) {
            onChange(newValue);
        }
    };

    return (

        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button onClick={() => changePage(-1)} disabled={value <= 1}>
                Previous
            </Button>
            <Typography>
                Page {value} of {maxPage}
            </Typography>
            <Button onClick={() => changePage(1)} disabled={value >= (maxPage || 1)}>
                Next
            </Button>
        </Box>

    );
};

export default LabellerPageNavigator;
