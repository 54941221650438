import React, { useState } from 'react';
import { Grid } from '@mui/material';
import LabellerCanvas from './LabellerCanvas';
import { BoundingBox, Label, PageTag } from './labellerTypes';
import LabellerSidebar from './LabellerSidebar';

const LabellerPage: React.FC = () => {
    const [boxes, setBoxes] = useState<BoundingBox[]>([]);
    const [labels, setLabels] = useState<Label[]>(JSON.parse(localStorage.getItem('labels') || '[{ "name": "Label 1", "color": "#ff7f7f" }]'));
    const [tags, setTags] = useState<PageTag[]>([]);


    // When labels change, persist them to local storage
    React.useEffect(() => {
        localStorage.setItem('labels', JSON.stringify(labels));
    }, [labels]);



    return (
        <Grid container spacing={2} sx={{ height: '100vh', p: 2 }}>
            <Grid item xs={9}>
                <LabellerCanvas value={boxes} onChange={setBoxes} labels={labels} tags={tags} onTagsChange={setTags} />
            </Grid>
            <Grid item xs={3}>
                <LabellerSidebar boxes={boxes} onBoxesChange={setBoxes} labels={labels} onLabelsChange={(setLabels)} tags={tags} onTagsChange={setTags} />
            </Grid>
        </Grid>
    );
};

export default LabellerPage;