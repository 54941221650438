import React, { useEffect, useState } from "react";
import {
  useGetProductsQuery,
  useDeleteProductMutation,
  useDeleteUserMutation,
  useGetUsersQuery,
} from "../../app/services/appApi";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { IUserDelete, IUserRead, IUserBase, IUserCreate, IUserUpdate} from "../auth/types";
import { Box, Button, Typography } from "@mui/material";
import Stack from "@mui/system/Stack";
import { withAuth } from "../auth/authWrappers";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import UserFormDialog from "./UserFormDialog";
import AddIcon from '@mui/icons-material/Add';
import UserSearchBar from "./UserSearchBar";
import { useNavigate } from "react-router-dom";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

const UserListPage = () => {

  const navigate = useNavigate();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });

  const [searchValue, setSearchValue] = useState("");
  const { data, isLoading } = useGetUsersQuery(
    {
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      search: searchValue,
    }
  );


  const columns: GridColDef[] = [
    {
      field: "id", headerName: "Id", width: 180

    },
    {
      field: "username",
      headerName: "Username",
      // Grow the column width
      flex: 1,
    },
    {
      field: "edit", headerName: "",
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => (
        <UserFormDialog userId={params.row.id} />
      ),
    },
    {
      field: "delete", headerName: "",
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => (
        <UserDeleteBtn userId={params.row.id} />
      ),
    },
  ];

  return (
    <>
    <Stack justifyContent="space-between" gap={2} direction="row" sx={{ mb: 2, alignItems: 'center' }}>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <PeopleOutlineIcon />
      <Typography variant="h5" component="h1" noWrap>
        <b> Utenti</b>
      </Typography>
    </Box>
      <Stack direction="row" gap={2} alignItems="center">
        <UserSearchBar onSearch={setSearchValue} size="small" />
        <UserFormDialog render={(props) => (
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            {...props}
          >
            Aggiungi
          </Button>
        )} />
      </Stack>
    </Stack>

      <DataGrid
        disableRowSelectionOnClick
        rows={data?.results || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
        sx={{ height: "80vh", my: 2 }}
        loading={isLoading}
        paginationMode="server"
        rowCount={data?.count || 0}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
    </>
  );
}


const UserDeleteBtn: React.FC<{ userId: IUserRead["id"] }> = ({ userId }) => {
  const [triggerDelete, { isLoading, isError, isSuccess }] = useDeleteUserMutation();
  const { t } = useTranslation();

  // Handle error and success notifications
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("Model deleted", { model: "User", count: 1 }), { variant: "success" });
    }
    if (isError) {
      enqueueSnackbar(t("Model deletion failed", { model: "User", count: 1 }), { variant: "error" });
    }
  }, [isSuccess, isError, t]);


  return (
    <IconButton
      onClick={() => triggerDelete(userId)}
      color="error"
      disabled={isLoading}
    >
      <DeleteOutlineOutlinedIcon />
    </IconButton>
  )


}

export default withAuth(UserListPage);
