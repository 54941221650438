import useAuthContext from "../auth/AuthContext";
import { useState, useEffect } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmailOutlined from '@mui/icons-material/EmailOutlined';

import { enqueueSnackbar } from "notistack";
import { useGetPipelineQuery } from "../../app/services/appApi";

interface IEmailCopyBtnProps {
  pipelineId: number;
}

const EmailCopyBtn: React.FC<IEmailCopyBtnProps> = (props)=> {
  const { data } = useGetPipelineQuery(props.pipelineId);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = () => {
    if (!navigator.clipboard) {
      enqueueSnackbar("Il tuo browser non supporta la copia negli appunti", { variant: "error" });
      return;
    }

    navigator.clipboard.writeText(data?.email_addr || "");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  useEffect(() => {
    if (isCopied) {
      enqueueSnackbar("Email copiata negli appunti", { variant: "success" });
      setTimeout(() => setIsCopied(false), 3000);
    }
  }, [isCopied]);

  return (

    <TextField
      variant="outlined"
      disabled
      value={data?.email_addr || ""}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <EmailOutlined />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleCopyToClipboard}
              disabled={isCopied}
            >
              {isCopied ? <CheckIcon /> : <ContentCopyIcon />}            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default EmailCopyBtn;

function useGetPipeline(pipelineId: {}): { data: any; } {
  throw new Error("Function not implemented.");
}
