import React, { useState, useRef } from 'react';
import { Box, Switch, FormControlLabel } from '@mui/material';
import PdfDocRenderer from './PdfDocRenderer';
import LabelSelector from './LabelSelector';
import { BoundingBox, Label, PageTag } from './labellerTypes';
import LabellerPageNavigator from './LabellerPageNavigator';
import PageTagSelector from './PageTagSelector';

interface ILabellerCanvasProps {
    value: BoundingBox[];
    labels: Label[];
    onChange: (boxes: BoundingBox[]) => void;
    tags: PageTag[];
    onTagsChange: (tags: PageTag[]) => void;
}

const BBOX_W_THRESH = 1; // 1% of the width
const BBOX_H_THRESH = 1; // 1% of the height

const LabellerCanvas: React.FC<ILabellerCanvasProps> = ({ value, onChange, labels, tags, onTagsChange }) => {
    const [activeLabel, setActiveLabel] = useState<Label | null>(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [startPoint, setStartPoint] = useState<{ x: number; y: number } | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const drawingAreaRef = useRef<HTMLDivElement>(null);
    const [pdfDimensions, setPdfDimensions] = useState<{ width: number; height: number } | null>(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [debugMode, setDebugMode] = useState(false);
    const [activateDraw, setActivateDraw] = useState(false);
    const [previewBox, setPreviewBox] = useState<BoundingBox | null>(null);

    const handleDimensionsChange = (width: number, height: number) => {
        console.log('Dimensions changed', width, height);
        setPdfDimensions({ width, height });
    };


    const handleMouseDown = (e: React.MouseEvent) => {
        console.log('Mouse down', e.clientX, e.clientY, 'Active Label:', activeLabel, 'Activate Draw:', activateDraw);
        if (!activeLabel || !activateDraw) {
            console.log('Returning early from mouseDown');
            return;
        }

        const rect = drawingAreaRef.current?.getBoundingClientRect();
        if (!rect) {
            console.log('No bounding rect found');
            return;
        }

        setIsDrawing(true);
        const startPoint = {
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
        };
        setStartPoint(startPoint);
        setPreviewBox({
            id: 'preview',
            label: activeLabel,
            x: startPoint.x,
            y: startPoint.y,
            width: 0,
            height: 0,
            page: currentPage,
        });
        console.log('Started drawing at', startPoint);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDrawing || !startPoint || !activateDraw) {
            return;
        }

        const rect = drawingAreaRef.current?.getBoundingClientRect();
        if (!rect) {
            console.log('No bounding rect found during mouse move');
            return;
        }

        const currentPoint = {
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
        };

        setPreviewBox(prev => {
            if (!prev) return null;
            const newBox = {
                ...prev,
                x: Math.min(startPoint.x, currentPoint.x),
                y: Math.min(startPoint.y, currentPoint.y),
                width: Math.abs(currentPoint.x - startPoint.x),
                height: Math.abs(currentPoint.y - startPoint.y),
            };
            console.log('Updating preview box', newBox);
            return newBox;
        });
    };

    const handleMouseUp = (e: React.MouseEvent) => {
        console.log('Mouse up', e.clientX, e.clientY, 'Is Drawing:', isDrawing, 'Start Point:', startPoint, 'Active Label:', activeLabel, 'Activate Draw:', activateDraw);
        if (!isDrawing || !startPoint || !activeLabel || !activateDraw || !pdfDimensions) {
            console.log('Returning early from mouseUp');
            return;
        }

        const rect = drawingAreaRef.current?.getBoundingClientRect();
        if (!rect) {
            console.log('No bounding rect found during mouse up');
            return;
        }

        const endPoint = {
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
        };

        const newBox: BoundingBox = {
            id: Math.random().toString(36).substr(2, 9),
            label: activeLabel,
            x: Math.min(startPoint.x, endPoint.x) / pdfDimensions.width * 100,
            y: Math.min(startPoint.y, endPoint.y) / pdfDimensions.height * 100,
            width: Math.abs(endPoint.x - startPoint.x) / pdfDimensions.width * 100,
            height: Math.abs(endPoint.y - startPoint.y) / pdfDimensions.height * 100,
            page: currentPage,
        };

        if (newBox.width < BBOX_W_THRESH || newBox.height < BBOX_H_THRESH) {
            console.log('Ignoring box due to small size', newBox);
            setIsDrawing(false);
            setStartPoint(null);
            setPreviewBox(null);
            return;
        }

        console.log('Adding new box', newBox);
        onChange([...value, newBox]);
        setIsDrawing(false);
        setStartPoint(null);
        setPreviewBox(null);
    };


    const handleBoxDelete = (id: string) => {
        onChange(value.filter(box => box.id !== id));
    };

    return (
        <Box sx={{ height: '100%', p: 2 }}>
            <LabelSelector activeLabel={activeLabel} onLabelSelect={setActiveLabel} labels={labels} />
            <FormControlLabel
                control={<Switch checked={debugMode} onChange={(e) => setDebugMode(e.target.checked)} />}
                label="Debug Mode"
            />
            <FormControlLabel
                control={<Switch checked={activateDraw} onChange={(e) => setActivateDraw(e.target.checked)} />}
                label="Activate Draw"
            />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: 2,
                    mt: 2,
                }}>
                <Box
                    sx={{
                        position: 'relative',
                        width: pdfDimensions ? pdfDimensions.width : '100%',
                        height: pdfDimensions ? 'unset' : '300px',
                    }}
                >
                    <PdfDocRenderer
                        onFileSelect={setFile}
                        file={file}
                        onPageChange={setCurrentPage}
                        debugMode={debugMode}
                        onMaxPageChange={setMaxPage}
                        page={currentPage}
                        onDimensionsChange={handleDimensionsChange}
                    />
                    {activateDraw && (
                        <Box
                            ref={drawingAreaRef}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                pointerEvents: 'auto',
                                backgroundColor: debugMode ? '#ff000010' : 'transparent',
                                border: debugMode ? '1px solid red' : 'none',
                                zIndex: 1000,
                                cursor: activeLabel ? 'crosshair' : 'default',
                            }}
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}
                            onMouseUp={handleMouseUp}
                        >
                            {value
                                .filter((box) => box.page === currentPage)
                                .map((box) => (
                                    <Box
                                        key={box.id}
                                        sx={{
                                            position: 'absolute',
                                            left: `${box.x}%`,
                                            top: `${box.y}%`,
                                            width: `${box.width}%`,
                                            height: `${box.height}%`,
                                            border: `2px solid ${box.label.color}`,
                                            backgroundColor: `${box.label.color}33`,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleBoxDelete(box.id)}
                                    />
                                ))}
                            {previewBox && pdfDimensions && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        left: `${(previewBox.x / pdfDimensions.width) * 100}%`,
                                        top: `${(previewBox.y / pdfDimensions.height) * 100}%`,
                                        width: `${(previewBox.width / pdfDimensions.width) * 100}%`,
                                        height: `${(previewBox.height / pdfDimensions.height) * 100}%`,
                                        border: `2px dashed ${previewBox.label.color}`,
                                        backgroundColor: `${previewBox.label.color}33`,
                                        pointerEvents: 'none',
                                    }}
                                />
                            )}
                        </Box>
                    )}
                </Box>
                <LabellerPageNavigator value={currentPage} onChange={setCurrentPage} maxPage={maxPage} />
                <PageTagSelector
                    tagsOnPage={tags.filter((t: PageTag) => t.page === currentPage).map((t: PageTag) => t.tagName)}
                    onChangeTagsOnPage={(newTags) => {
                        onTagsChange([...tags.filter((t: PageTag) => t.page !== currentPage), ...newTags.map((t) => ({ page: currentPage, tagName: t }))]);
                    }}
                />

            </Box>
        </Box>
    );
};

export default LabellerCanvas;
