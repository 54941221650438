import React, { useEffect, useState } from "react";
import {
  useGetProductsQuery,
  useDeleteProductMutation,
} from "../../app/services/appApi";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { IProductRecord } from "./productTypes";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import Stack from "@mui/system/Stack";
import { withAuth } from "../auth/authWrappers";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ProductFormDialog from "./ProductFormDialog";
import AddIcon from '@mui/icons-material/Add';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import ProductSearchBar from "./ProductSearchBar";
import { useNavigate } from "react-router-dom";
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';

const ProductListPage = () => {

  const navigate = useNavigate();

  const handleUploadClick = () => {
    navigate("/products/upload");
  };

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });

  const [searchValue, setSearchValue] = useState("");
  const { data, isLoading } = useGetProductsQuery(
    {
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      search: searchValue,
    }
  );


  const columns: GridColDef[] = [
    {
      field: "code", headerName: "Codice", width: 180

    },
    {
      field: "name",
      headerName: "Nome",
      // Grow the column width
      flex: 1,
    },
    // {
    //   field: "edit", headerName: "",
    //   //@ts-ignore-next-line
    //   renderCell: (params: GridValueGetterParams) => (
    //     <ProductFormDialog productId={params.row.id} />
    //   ),
    // },
    // {
    //   field: "delete", headerName: "",
    //   //@ts-ignore-next-line
    //   renderCell: (params: GridValueGetterParams) => (
    //     <ProductDeleteBtn productId={params.row.id} />
    //   ),
    // },
  ];

  return (
    <>
      <Stack justifyContent="space-between" gap={2} direction="row" sx={{ mb: 2, alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <CategoryOutlinedIcon />
          <Typography variant="h5" component="h1" noWrap>
            <b> Articoli</b>
          </Typography>
        </Box>


        <Stack direction="row" gap={2} alignItems="center">
          <ProductSearchBar onSearch={setSearchValue} size="small" />
          <ProductFormDialog render={(props) => (
            <Tooltip title="Non hai i permessi per modificare o l'elenco è sincronizzato automaticamente con il gestionale">
              <div>

                <Button
                  startIcon={<AddIcon />}
                  variant="contained"
                  color="primary"
                  disabled
                  {...props}
                >
                  Aggiungi
                </Button>
              </div>
            </Tooltip>
          )} />
          <Tooltip title="Non hai i permessi per caricare o l'elenco è sincronizzato automaticamente con il gestionale">
            <div>

              <Button
                startIcon={<UploadFileOutlinedIcon />}
                variant="outlined"
                color="primary"
                onClick={handleUploadClick}
                disabled
              >
                Carica CSV
              </Button>
            </div>
          </Tooltip>
        </Stack>
      </Stack>

      <DataGrid
        disableRowSelectionOnClick
        rows={data?.results || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
        sx={{ height: "80vh", my: 2 }}
        loading={isLoading}
        paginationMode="server"
        rowCount={data?.count || 0}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
    </>
  );
}





const ProductDeleteBtn: React.FC<{ productId: Pick<IProductRecord, "id"> }> = ({ productId }) => {
  const [triggerDelete, { isLoading, isError, isSuccess }] = useDeleteProductMutation();
  const { t } = useTranslation();

  // Handle error and success notifications
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("Model deleted", { model: "Product", count: 1 }), { variant: "success" });
    }
    if (isError) {
      enqueueSnackbar(t("Model deletion failed", { model: "Product", count: 1 }), { variant: "error" });
    }
  }, [isSuccess, isError, t]);


  return (
    <IconButton
      onClick={() => triggerDelete(productId)}
      color="error"
      disabled={isLoading}
    >
      <DeleteOutlineOutlinedIcon />
    </IconButton>
  )


}

export default withAuth(ProductListPage);
