import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useForm, Controller } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import useAuthContext from "./AuthContext";
import { withNoAuth } from "./authWrappers";
import { useTranslation } from "react-i18next";

function LoginPage() {
  const { t } = useTranslation();

  const { login } = useAuthContext();

  // Form validation hook
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { username: "", password: "" },
  });

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('Log in')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit(login)} sx={{ mt: 1 }}>
            <Controller
              name="username"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="normal"
                  autoComplete="off"
                  required
                  fullWidth
                  name="username"
                  label={t('Username')}
                  type="username"
                  error={!!errors.username}
                  autoFocus
                  helperText={errors.username && t('Required field')}
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label={t('Password')}
                  type="password"
                  autoComplete="current-password"
                  error={!!errors.password}
                  helperText={errors.password && t('Check your password again')}
                  {...field}
                />
              )}
            />
            {/* {isError && (
              <Alert severity="error">{t('Ricontrolla username e password')}</Alert>
            )} */}
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            // loading={isLoading}
            >
              {t('Sign In')}
            </LoadingButton>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default withNoAuth(LoginPage);
