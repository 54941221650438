import React, { useState, useEffect } from 'react';
import { FormProvider, UseFormReturn } from "react-hook-form";
import { Box, Tooltip, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from "@mui/system/Stack";
import { IEmailRecord } from "./emailTypes";
import DynamicEmailForm from './dynamicUi/DynamicEmailForm';
import RegisterEmailBtn from './RegisterEmailBtn';
import CertifyEmailBtn from './CertifyEmailBtn';
import { useForm } from "react-hook-form";
import { enqueueSnackbar } from 'notistack';
import RegisteredEmailAlert from './RegisteredEmailAlert';
import { EmailViewer } from './dynamicUi/FieldRenderer';

export interface EmailEditableFieldsProps {
    email: IEmailRecord
    isLoading: boolean
    onSubmit: (data: IEmailRecord) => void
    onTarget: (target: EmailViewer) => void
    page_idx: number
    tabID: string
}

const EmailEditableFields: React.FC<EmailEditableFieldsProps> = ({ email, isLoading, onSubmit, onTarget, page_idx, tabID}) => {
    const form = useForm<any>({ defaultValues: email.parsed_info });
    useEffect(() => {
        form.reset(email.parsed_info);
    }, [email, form]);

    const [isDebug, setIsDebug] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleError = (errors: any) => {
        enqueueSnackbar("Ci sono errori nel form", { variant: 'error' });
    };

    const disableAll = email.is_registered || isLoading;

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit, handleError)} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Box sx={{
                    overflowY: 'auto', flexGrow: 1,
                    p: 1
                }}>
                    {email.is_registered && <RegisteredEmailAlert sx={{ mb: 2 }} />}
                    <DynamicEmailForm uiSchema={email.ui_schema} email={email} control={form.control} disabled={disableAll} onTarget={onTarget} page_idx={page_idx} tabID={tabID}/>
                </Box>
                <Stack direction={{ xs: 'column', sm: 'row' }} gap={1} sx={{ mt: 2 }} justifyContent="flex-end">
                    <Box sx={{ flexGrow: 1 }}>
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Box>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox
                                    checked={isDebug}
                                    onChange={() => setIsDebug(!isDebug)}
                                />} label="Mostra campi avanzati" />
                            </FormGroup>
                        </MenuItem>
                    </Menu>
                    <Tooltip title="Salva i dati">
                        <span>
                            <LoadingButton type="submit"
                                loading={isLoading}
                                disabled={email.is_registered} variant='contained' color='primary'
                                startIcon={<SaveIcon />}
                            >Salva</LoadingButton>
                        </span>
                    </Tooltip>
                    <CertifyEmailBtn email={email} disable={form.formState.isDirty} />
                    <RegisterEmailBtn email={email} disable={form.formState.isDirty} />
                </Stack>
            </form>
        </FormProvider>
    );
};

export default EmailEditableFields;