import React from 'react';
import { Controller, Control, ValidationRule, useFormContext } from "react-hook-form";
import { TextField, MenuItem, FormHelperText, InputAdornment, IconButton } from "@mui/material";
import { Email, GpsFixed as GpsFixedIcon, Height } from "@mui/icons-material";
import { UISchemaField } from './dynamicUiTypes';
import ServerSideSearchSelect2 from '../../input/select/ServerSideSearchSelect2';
import { useLazyGetAutocompleteQuery } from "../../../app/services/appApi";
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { FormControl, InputLabel, Select } from "@mui/material";
import { HighlightArea } from '@react-pdf-viewer/highlight';
import { watch } from 'fs';

interface FieldRendererProps {
    fieldName: string;
    fieldSchema: UISchemaField;
    control: Control<any>;
    index?: number;
    disabled?: boolean;
    onTarget: (target: EmailViewer) => void;
    page_idx: number;
    tabID: string;
}

export interface EmailViewer extends HighlightArea {
    pageIndex: number;
    height: number;
    width: number;
    left: number;
    top: number;
    tabID: string;
    label: string;
    value: string;
}

const FieldRenderer: React.FC<FieldRendererProps> = ({ fieldName, fieldSchema, control, index, disabled, onTarget, page_idx }) => {
    const name = index !== undefined ? `items[${index}].${fieldName}.value` : fieldName + ".value";
    const validation: Record<string, ValidationRule<any>> = {};
    const form = useFormContext();
    const bboxFieldName = index !== undefined ? `items[${index}].${fieldName}.bbox` : fieldName + ".bbox";
    const bboxValues = form.watch(bboxFieldName);
    const isBboxAbsent = Array.isArray(bboxValues) && bboxValues.every(v => v === 0);

    const handleTargetClick = () => {
        const bboxFieldName = index !== undefined ? `items[${index}].${fieldName}.bbox` : fieldName + ".bbox";
        const src_refFieldName = index !== undefined ? `items[${index}].${fieldName}.src_ref` : `${fieldName}.src_ref`;
        const page_idxFieldName = index !== undefined ? `items[${index}].${fieldName}.page_idx` : `${fieldName}.page_idx`;

        console.log("Getting bbox values for", bboxFieldName);
        const bboxValues = form.getValues(bboxFieldName);
        console.log("bboxValues", bboxValues);
        if (!bboxValues) {
            console.info("No bbox values found for", bboxFieldName);
            return;
        }

        const src_ref = form.getValues(src_refFieldName);
        console.log("src_ref", src_ref);
        if (!src_ref) {
            console.info("No src_ref found for", src_refFieldName);
            return;
        }
        const { type, id } = src_ref;
        console.log("Extracted src_ref type:", type, "id:", id);
        if (!type || !id) {
            console.info("No type or id found in src_ref for", src_refFieldName);
            return;
        }

        const page_idxValue = form.getValues(page_idxFieldName);
        console.log("page_idx", page_idxValue);
        if (page_idxValue === undefined) {
            console.info("No page_idx found for", page_idxFieldName);
            return;
        }

        const pageIndex = page_idxValue;
        let tabString: string;
        if (type === 'attachment') {
            tabString = 'attachment' + "::" + id;
        } else {
            tabString = 'email';
        }

        const PADDING = 0.01;
        const x0 = (Math.max(bboxValues[0] - PADDING, 0));
        const x1 = (Math.min(bboxValues[2] + PADDING, 1));
        const y0 = (Math.max(bboxValues[1] - PADDING, 0));
        const y1 = (Math.min(bboxValues[3] + PADDING, 1));

        const width = (x1 - x0) * 100;
        const height = (y1 - y0) * 100;

        const targetBbox: EmailViewer = {
            width,
            height,
            left: x0 * 100,
            top: y0 * 100,
            pageIndex: pageIndex,
            tabID: tabString,
            label: fieldSchema.label,
            value: form.getValues(name) || "",
        };
        console.log("targetBbox", targetBbox);
        console.log("tabID", tabString);
        onTarget(targetBbox);
    };

    if (fieldSchema.validation) {
        /*if (fieldSchema.validation.required) {
            validation.required = { value: true, message: `${fieldSchema.label} è obbligatorio.` };
        }*/
        if (fieldSchema.validation.min !== undefined) {
            validation.min = { value: fieldSchema.validation.min, message: `${fieldSchema.label} deve essere almeno ${fieldSchema.validation.min}.` };
        }
        if (fieldSchema.validation.max !== undefined) {
            validation.max = { value: fieldSchema.validation.max, message: `${fieldSchema.label} deve essere al massimo ${fieldSchema.validation.max}.` };
        }
        if (fieldSchema.validation.pattern) {
            validation.pattern = { value: new RegExp(fieldSchema.validation.pattern), message: 'Formato non valido.' };
        }
    }


    const renderTargetButton = () => (
        !isBboxAbsent && (
            <InputAdornment position="end">
                <IconButton
                    edge="end"
                    onClick={handleTargetClick}
                    size="small"
                >
                    <GpsFixedIcon />
                </IconButton>
            </InputAdornment>
        )
    );

    return (
        <Controller
            name={name}
            control={control}
            rules={validation}
            disabled={disabled}
            render={({ field, fieldState: { error } }) => {
                switch (fieldSchema.type) {
                    case 'string':
                        return (
                            <TextField
                                {...field}
                                value={field.value || ""}
                                label={fieldSchema.label}
                                fullWidth
                                size="small"
                                error={!!error}
                                helperText={error?.message}
                                InputProps={{ endAdornment: renderTargetButton() }}
                                onFocus={handleTargetClick}
                            />
                        );
                    case 'multiline':
                        return (
                            <TextField
                                {...field}
                                value={field.value || ""}
                                label={fieldSchema.label}
                                fullWidth
                                size="small"
                                multiline
                                rows={3}
                                error={!!error}
                                helperText={error?.message}
                                InputProps={{ endAdornment: renderTargetButton() }}
                                onFocus={handleTargetClick}
                            />
                        );
                    case 'int':
                        return (
                            <TextField
                                {...field}
                                value={field.value || ""}
                                label={fieldSchema.label}
                                fullWidth
                                size="small"
                                type="number"
                                inputProps={{ step: 1 }}
                                error={!!error}
                                helperText={error?.message}
                                InputProps={{ endAdornment: renderTargetButton() }}
                                onFocus={handleTargetClick}
                            />
                        );
                    case 'float':
                        return (
                            <TextField
                                {...field}
                                value={field.value || ""}
                                label={fieldSchema.label}
                                fullWidth
                                size="small"
                                type="number"
                                inputProps={{ step: 0.01 }}
                                error={!!error}
                                helperText={error?.message}
                                InputProps={{ endAdornment: renderTargetButton() }}
                                onFocus={handleTargetClick}
                            />
                        );
                    case 'date':
                        return <DatePicker
                            {...field}
                            value={field.value ? moment(field.value) : null}
                            label={fieldSchema.label}
                            formatDensity="spacious"
                            onError={(newError) => console.log(newError)}
                            slotProps={{
                                textField: {
                                    helperText: error?.message,
                                    // error: !!error,
                                    size: "small",
                                    InputProps: {
                                        endAdornment: renderTargetButton()
                                    },
                                    onFocus: handleTargetClick
                                },
                            }}
                        />;
                    case 'search':
                        return (
                            <ServerSideSearchSelect2
                                {...field}
                                label={fieldSchema.label}
                                searchModel={fieldSchema.searchModel}
                                lazyQuery={useLazyGetAutocompleteQuery}
                                inputProps={{
                                    placeholder: "Digita per cercare...",
                                    size: "small",
                                    error: !!error,
                                    helperText: error?.message,
                                    InputProps: {
                                        endAdornment: renderTargetButton(),
                                        onFocus: handleTargetClick
                                    }
                                }}
                            />);
                    case 'enum':
                        return (
                            <FormControl fullWidth size="small" error={!!error} {...field}
                                onFocus={handleTargetClick}
                            >
                                <InputLabel id={`${name}-label`}>{fieldSchema.label}</InputLabel>
                                <Select
                                    {...field}
                                    labelId={`${name}-label`}
                                    id={`${name}-select`}
                                    label={fieldSchema.label}
                                >
                                    {fieldSchema.options?.map(({ value, label }) => (
                                        <MenuItem key={value} value={value}>{label}</MenuItem>
                                    ))}
                                </Select>
                                {error && <FormHelperText>{error.message}</FormHelperText>}
                            </FormControl>
                        );
                    default:
                        return (
                            <FormHelperText error>
                                Error: Field type not supported
                            </FormHelperText>
                        );
                }
            }}
        />
    );
};

export default FieldRenderer;