import React, { useState, useEffect } from 'react';
import { Paper, Typography, TextField, Button, Alert, Tabs, Tab } from '@mui/material';
import { BoundingBox, Label, PageTag } from "./labellerTypes";
import MergedMetadata from './MergedMetadata';

interface ILabellerSidebarProps {
    boxes: BoundingBox[];
    labels: Label[];
    onBoxesChange?: (boxes: BoundingBox[]) => void;
    onLabelsChange?: (labels: Label[]) => void;
    tags?: PageTag[];
    onTagsChange?: (tags: PageTag[]) => void;

}

const LabellerSidebar: React.FC<ILabellerSidebarProps> = ({ boxes, labels, tags, onBoxesChange, onLabelsChange, onTagsChange }) => {
    const [boxesJsonText, setBoxesJsonText] = useState('');
    const [labelsJsonText, setLabelsJsonText] = useState('');
    const [tagsJsonText, setTagsJsonText] = useState('');
    const [jsonError, setJsonError] = useState<string | null>(null);
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        setBoxesJsonText(JSON.stringify(boxes, null, 2));
    }, [boxes]);

    useEffect(() => {
        setLabelsJsonText(JSON.stringify(labels, null, 2));
    }, [labels]);

    useEffect(() => {
        setTagsJsonText(JSON.stringify(tags, null, 2));
    }, [tags]);

    const handleBoxesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newText = event.target.value;
        setBoxesJsonText(newText);

        try {
            const parsedBoxes = JSON.parse(newText) as BoundingBox[];
            if (Array.isArray(parsedBoxes)) {
                onBoxesChange?.(parsedBoxes);
                setJsonError(null);
            }
        } catch (error) {
            setJsonError('Invalid JSON format');
            console.warn('Invalid JSON:', error);
        }
    };

    const handleLabelsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newText = event.target.value;
        setLabelsJsonText(newText);

        try {
            const parsedLabels = JSON.parse(newText) as Label[];
            if (typeof parsedLabels === 'object' && parsedLabels !== null) {
                onLabelsChange?.(parsedLabels);
                setJsonError(null);
            }
        } catch (error) {
            setJsonError('Invalid JSON format');
            console.warn('Invalid JSON:', error);
        }
    };

    const handleTagsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newText = event.target.value;
        setTagsJsonText(newText);

        try {
            const parsedTags = JSON.parse(newText) as PageTag[];
            if (Array.isArray(parsedTags)) {
                onTagsChange?.(parsedTags);
                setJsonError(null);
            }
        } catch (error) {
            setJsonError('Invalid JSON format');
            console.warn('Invalid JSON:', error);
        }
    }

    const handleCopyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            console.log('Copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    return (
        <Paper sx={{
            p: 2,
            height: '90vh',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
        }}>
            <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab label="Bounding Boxes" />
                <Tab label="Tags" />
                <Tab label="edit Labels" />
                <Tab label="Merged Metadata" />

            </Tabs>
            {tabIndex === 0 && (
                <>
                    <TextField
                        multiline
                        fullWidth
                        variant="outlined"
                        value={boxesJsonText}
                        onChange={handleBoxesChange}
                        sx={{
                            mt: 1,
                            flexGrow: 1,
                            '& .MuiInputBase-root': {
                                height: '100%',
                            },
                            '& .MuiInputBase-input': {
                                height: '100% !important',
                                overflow: 'auto !important',
                            },
                        }}
                    />
                    {jsonError && <Alert severity="error" sx={{ mt: 2 }}>{jsonError}</Alert>}
                    <Button
                        variant="contained"
                        onClick={() => handleCopyToClipboard(boxesJsonText)}
                        sx={{ mt: 2 }}
                    >
                        Copy to Clipboard
                    </Button>
                </>
            )}
            {tabIndex === 1 && (
                <>
                    <TextField
                        multiline
                        fullWidth
                        variant="outlined"
                        value={tagsJsonText}
                        onChange={handleTagsChange}
                        sx={{
                            mt: 1,
                            flexGrow: 1,
                            '& .MuiInputBase-root': {
                                height: '100%',
                            },
                            '& .MuiInputBase-input': {
                                height: '100% !important',
                                overflow: 'auto !important',
                            },
                        }}
                    />
                    {jsonError && <Alert severity="error" sx={{ mt: 2 }}>{jsonError}</Alert>}
                    <Button
                        variant="contained"
                        onClick={() => handleCopyToClipboard(tagsJsonText)}
                        sx={{ mt: 2 }}
                    >
                        Copy to Clipboard
                    </Button>
                </>
            )}
            {tabIndex === 2 && (
                <>
                    <TextField
                        multiline
                        fullWidth
                        variant="outlined"
                        value={labelsJsonText}
                        onChange={handleLabelsChange}
                        sx={{
                            mt: 1,
                            flexGrow: 1,
                            '& .MuiInputBase-root': {
                                height: '100%',
                            },
                            '& .MuiInputBase-input': {
                                height: '100% !important',
                                overflow: 'auto !important',
                            },
                        }}
                    />
                    {jsonError && <Alert severity="error" sx={{ mt: 2 }}>{jsonError}</Alert>}
                    <Button
                        variant="contained"
                        onClick={() => handleCopyToClipboard(labelsJsonText)}
                        sx={{ mt: 2 }}
                    >
                        Copy to Clipboard
                    </Button>
                </>
            )}
            {tabIndex === 3 && (
                <MergedMetadata
                    boundingBoxes={boxes}
                    tags={tags}
                />
            )}



        </Paper>
    );
};

export default LabellerSidebar;
