import React from 'react';
import { Button, Stack } from '@mui/material';
import { Label } from './labellerTypes';

interface ILabelSelectorProps {
    activeLabel: Label | null;
    labels: Label[];
    onLabelSelect: (label: Label) => void;
}

const LabelSelector: React.FC<ILabelSelectorProps> = ({
    activeLabel,
    onLabelSelect,
    labels
}) => {
    return (
        <Stack direction="row" gap={1} flexWrap={"wrap"}>
            {labels.map(label => (
                <Button
                    key={label.name}
                    variant={activeLabel === label ? 'contained' : 'outlined'}
                    style={{ backgroundColor: label.color }}
                    onClick={() => onLabelSelect(label)}
                    size="small"
                >
                    {label.name}
                </Button>
            ))}
        </Stack >
    );
};

export default LabelSelector;