import Stack from '@mui/material/Stack';
import EmailPrevNextBtn from './EmailPrevNextBtn';
import DownloadCsvBtn from './DownloadCsvBtn';
import EmailStatusChip from '../EmailStatusChip';
import { IEmailRecord } from '../emailTypes';
import DeleteEmailBtn from './DeleteEmailBtn';

interface EmailToolbarProps {
    emailId: number;
    email?: IEmailRecord;
}

export default function EmailToolbar({ emailId, email }: EmailToolbarProps) {
    return (
        <Stack direction="row" gap={1} alignItems="center">
            {email &&
                <>
                    <EmailStatusChip
                        level={email.status}
                        variant="outlined"
                    />
                    <EmailPrevNextBtn action="next" email={email} />
                    <EmailPrevNextBtn action="prev" email={email} />
                </>
            }
            <DownloadCsvBtn variant="icon" ids={[emailId]} disabled={email?.status !== 'PROCESSED'} />
            <DeleteEmailBtn emailId={emailId} />
            {/* <Tooltip title="Reprocess">
                <ReprocessEmailButton emailId={emailId} />
            </Tooltip> */}

        </Stack>
    );
}