import { useContext, useEffect } from "react";
import { AuthzContext } from "./MyAuthzProvider";

interface UseAuthorization {
  can: (act: string, obj: string) => boolean;
  cannot: (act: string, obj: string) => boolean;
}

export function useAuthorization(): UseAuthorization {
  const context = useContext(AuthzContext);

  if (!context) {
    throw new Error("useAuthorization must be used within a MyAuthzProvider");
  }

  // useEffect(() => {
  //   // log sub when it changes
  //   console.debug("Sub changed: ", context.sub);
  // }, [context.sub]);


  const can = (act: string, obj: string): boolean => {
    // console.debug(`Checking if ${context.sub} can ${act} ${obj}`);
    if (!context.enforcer || !context.sub) return false;
    //TODO: use async version maybe?
    return context.enforcer.enforceSync("string", context.sub, obj, act);
  };

  const cannot = (act: string, obj: string): boolean => !can(act, obj);

  return { can, cannot };
}
