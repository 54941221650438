import React from "react";
import MyAuthzProvider from "@core/auth/authz/MyAuthzProvider";
import EmailListPage from "./features/email/EmailListPage";
import EmailDetailPage from "./features/email/EmailDetailPage";
import NavigationDrawer from "./features/navigation/NavigationDrawer";
import CssBaseline from "@mui/material/CssBaseline";
import { Routes, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import DebugPage from "./features/debug";
import { Role } from "./features/auth/authWrappers";
import AuthContextProvider from "./features/auth/AuthProvider";
import Login from "./features/auth/Login";
import ProfilePage from "./features/auth/Profile";
import './i18n';
import SettingsPage from "./features/Settings";
import usePageTracking from "./features/tracking/useTracking";
import UploadPage from "./features/upload/UploadPage";
import ProductListPage from "./features/product/ProductListPage";
import ContactListPage from "./features/contact/ContactListPage";
import BenchmarkListPage from "./features/benchmark/BenchmarkListPage";
import BenchmarkDetailPage from "./features/benchmark/BenchmarkDetailPage";
import PipelineListPage from "./features/pipeline/PipelineListPage";
import { DevSettingsProvider } from "./features/debug/devSettings/DevSettings";
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import 'moment/locale/it'
import UserDeactivatedPage from "./features/auth/UserDeactivatedPage";
import SyncPage from "./features/Sync/SyncPage";
import UserListPage from "./features/add_user/UserListPage";
import LabellerPage from "@features/labeller/LabellerPage";

function App() {
  usePageTracking();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DevSettingsProvider>
        <AuthContextProvider>
          <MyAuthzProvider>
            <SnackbarProvider autoHideDuration={4000} />
            <CssBaseline />
            <NavigationDrawer>
              <Routes>
                <Route
                  path="/profile"
                  element={<ProfilePage allowedRoles={[Role.User]} />}
                />
                <Route path="/deactivated" element={<UserDeactivatedPage allowedRoles={[Role.User]} />} />

                <Route
                  path="/settings"
                  element={<SettingsPage allowedRoles={[Role.User]} />}
                />
                <Route path="/login" element={<Login />} />
                <Route
                  path="/"
                  element={<PipelineListPage allowedRoles={[Role.User]} />}
                />
                <Route
                  path="/pipeline/:pipelineId/email/:id"
                  element={<EmailDetailPage allowedRoles={[Role.User]} />}
                />

                <Route path="/products" element={<ProductListPage allowedRoles={[Role.User]} />} />
                <Route path="/products/upload" element={<UploadPage allowedRoles={[Role.User]} recordType="products" />} />

                <Route path="/contacts" element={<ContactListPage allowedRoles={[Role.User]} />} />
                <Route path="/contacts/upload" element={<UploadPage allowedRoles={[Role.User]} recordType="contacts" />} />

                <Route path="/benchmarks" element={<BenchmarkListPage allowedRoles={[Role.User]} />} />
                <Route
                  path="/benchmark/:id"
                  element={<BenchmarkDetailPage allowedRoles={[Role.User]} />}
                />

                <Route path="/pipeline" element={<PipelineListPage allowedRoles={[Role.User]} />} />
                <Route
                  path="/pipeline/:id"
                  element={<EmailListPage allowedRoles={[Role.User]} />}
                />

                <Route path="/users" element={<UserListPage allowedRoles={[]} />} />


                <Route path="/debug" element={<DebugPage />} />
                <Route path="/sync" element={<SyncPage allowedRoles={[Role.User]} />} />
                <Route path="/labeller" element={<LabellerPage />} />
              </Routes>

            </NavigationDrawer>
          </MyAuthzProvider>
        </AuthContextProvider>
      </DevSettingsProvider>
    </LocalizationProvider >

  );
}

export default App;
